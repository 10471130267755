import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":"form-list"}},[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.selectRow(null), _vm.updateDialog('edit')}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Create Form")])],1)],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('task-type-select',{attrs:{"multiple":false},on:{"task-type-select":_vm.updateType}})],1)],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.formList,"options":_vm.options,"loading":_vm.loading,"group-by":"isActive","group-desc":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.selectRow(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudPrintOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Print")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.selectRow(item), _vm.updateDialog('render')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Form")])]),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c(VList,_vm._l((_vm.actionOptions),function(option,i){return _c(VListItem,{key:i,attrs:{"href":"javascript:void(0)"}},[(option.title === 'Edit')?_c(VListItemTitle,{on:{"click":function($event){_vm.selectRow(item), _vm.updateDialog('edit')}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(option.icon)+" ")]),_c('span',[_vm._v(_vm._s(option.title))])],1):_vm._e(),(option.title !== 'Edit')?_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(option.icon)+" ")]),_c('span',[_vm._v(_vm._s(option.title))])],1):_vm._e()],1)}),1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}}),_c(VDialog,{attrs:{"persistent":"","max-width":"75%"},model:{value:(_vm.renderDialog),callback:function ($$v) {_vm.renderDialog=$$v},expression:"renderDialog"}},[_c('div',[_c(VCard,[(_vm.renderDialog)?_c('form-render',{attrs:{"form-data":_vm.selectedForm,"show-addendum":false},on:{"update-dialog":_vm.updateDialog}}):_vm._e()],1)],1)]),_c(VDialog,{attrs:{"persistent":"","max-width":"75%"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('div',[_c(VCard,[(_vm.editDialog)?_c('form-add-edit',{attrs:{"form-config":_vm.selectedForm},on:{"update-dialog":_vm.updateDialog}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }